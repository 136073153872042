import React from "react"
import logo from "../images/insureOnlineLogo.svg"
// import LanguageSelector from "./LanguageSelector"
import {useTranslation}               from 'gatsby-plugin-react-i18next';
import { Button, }                    from "react-bootstrap"
import { Helmet } from "react-helmet"

const Header = (props) => {

  const HARD_CODED_AUTO_QUOTE_URL = "https://auto-quote.insureonline.com/quotes/new"
  let QuoteUrl;
  if (props.quoteUrl) {
    QuoteUrl = props.quoteUrl;
  } else {
    QuoteUrl = process.env.GATSBY_APP_QUOTE_URL? process.env.GATSBY_APP_QUOTE_URL : HARD_CODED_AUTO_QUOTE_URL;
  }
  const baseurl = process.env.GATSBY_WEB_HOME_URL;

  const buttonStlyesOverrides = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"center",
    marginLeft:"10px"
  }

  const { t } = useTranslation();

  return (
    <header className="header">
      <Helmet>
        <script src="https://reviewsonmywebsite.com/js/v2/embed.js?id=c338ae55b38e487f19ae" type="text/javascript"></script>
      </Helmet>
      <div className="logo">
        <a href={baseurl}>
          <img src={logo} alt=""/>
        </a>
        <a href={baseurl}><button className="btn btn-header-about-us">About Us</button></a>
      </div>

      <div className="quote-btn" style={{...buttonStlyesOverrides}}>
        {/* <LanguageSelector /> */}
        <Button href={QuoteUrl} target={'_blank'} className="btn-orange" variant="primary-1">{t("header.buttonText")}</Button>
      </div>
    </header>
  )
}


export default Header